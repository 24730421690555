import { breakpoints, g } from '@pretto/bricks/components/layout'

import { CheckBoldMini } from '@pretto/picto'

import styled from 'styled-components'

export interface PromiseProps {
  text: string
  title: string
}

export const Promise: React.FC<PromiseProps> = ({ text, title, ...props }) => (
  <PromiseContainer {...props}>
    <PictoWrapper>
      <Picto />
    </PictoWrapper>

    <div>
      <Title>{title}</Title>
      <Description>{text}</Description>
    </div>
  </PromiseContainer>
)

const PromiseContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  gap: ${g(3)};
  flex: 1;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    flex-direction: row;
    align-items: start;
    gap: ${g(3)};
    text-align: unset;
  }
`

const PictoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${g(1)};
  background-color: ${({ theme }) => theme.colors.neutral5};
  border-radius: 100%;
  width: ${g(4)};
  height: ${g(4)};
  flex-shrink: 0;
`

const Picto = styled(CheckBoldMini)`
  display: block;
`

const Title = styled.div`
  ${({ theme }) => theme.typos.heading5};
  margin-bottom: ${g(1, -4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(1)};
  }
`

const Description = styled.div`
  ${({ theme }) => theme.typos.body4};
`
